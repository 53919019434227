import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MetaData } from '../components/common/meta';
import  SinglePostNew from '../components/CMS/singlePostNew';
import PageWrapper from '../components/PageWrapper';

const Post = ({ data, location }) => {
    const post = data.ghostPost

    if (!post.feature_image) {
        var page = data.allGhostPage.edges.find(page=>page.node.primary_tag && post.primary_tag && page.node.primary_tag.slug === post.primary_tag.slug)
        
        if (page)
            post.feature_image = page.node.feature_image;
    }            
    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <PageWrapper>

                <SinglePostNew post={post}/>

            </PageWrapper>
        </>
    );
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
            html
        }
        allGhostPage(filter: {tags: {elemMatch: {slug: {eq: "hash-news-cat"}}}}, sort: {fields: published_at, order: ASC}) {
            edges {
                node {
                    id
                    slug
                    title
                    primary_tag {
                        slug
                    }
                    feature_image
                }
            }
        }           
    }

`
