import React, { Component } from 'react';
import Link from 'gatsby-link';
import { css } from 'emotion';
import MobileNav from './mobile';
import media from '../../utils/media';
import { Box } from '../Layout';
import {StaticQuery, graphql } from 'gatsby'
import colors from '../../utils/colors';
import { TextBox, Button } from 'devextreme-react';
import { navigate } from '@reach/router';

const basicNav = css`
  display: flex;
  align-items: center;
  color: ${colors.accent};
  margin: 0;
  width: 100%;
  list-style-type: none;
  height: 3rem;
  font-weight: 400;
  & ul {
    width: 100%;
    list-style-type: none;
    display: flex;
    padding: 0;
    justify-content: center;
  }
  border-top: 4px solid #161616;
  border-bottom: 4px solid #161616;
  display: none;
  padding-left: 10px;
  padding-right: 10px;  
  & li img {
    display: inline-block;
    vertical-align: middle;
  }


  & li {
    margin-left: 1.25rem;

  }
`;

const fullNav = css`
  ${basicNav};
  font-size: 1.2rem;
  ${media.vlarge`
    display: flex;
  `};
`;

// Styles for the mobile View of the navigation
const mobileNav = css`
  ${basicNav};
  display: flex;
  font-size: 2rem;
  & li:last-child {
    font-weight: 600;
    cursor: pointer;
  }

  ${media.vlarge`
    display: none;
  `};
`;

// Styles for the overlay which pops up, when the menu is clicked
const mobileStyle = css`
  position: fixed;
  color: ${colors.accent};
  background-color: #e5eff5;
  display: block;
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  & ul {
    list-style-type: none;
    padding: 0;
    margin: 0.5rem 0 0 0;
    height: 100%;
    text-align: center;
    font-size: 2rem;
    & div {
      text-align: center;
    }
  }

  & ul li {
    margin-top: 0rem;
  }
  & div {
    font-weight: 600;
    text-align: right;
  }
`;

class SubNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileActive: false,
      searchActive: false,
      searchKeywords: null,
    };

    this.setSearchTextBox = (ref) => {
      if (ref != null)
          this.searchTextBox = ref.instance;
    };    
    this.toggleNav = this.toggleNav.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.onKeywordValueChanged = this.onKeywordValueChanged.bind(this);
    this.performPostSearch = this.performPostSearch.bind(this);
    this.setFocus = this.setFocus.bind(this);
  }


  setFocus() {
    this.searchTextBox.focus();
  }
  toggleNav() {
    const { mobileActive } = this.state;
    if (mobileActive) {
      this.setState({ mobileActive: false });
    } else {
      this.setState({ mobileActive: true });
    }
  }

  toggleSearch() {
    const { searchActive } = this.state;
    if (searchActive) {
      this.setState({ searchActive: false });
    } else {
      this.setState({ searchActive: true });
      
    }
  }  
  onKeywordValueChanged(e) {
    this.setState({searchKeywords: e.value});
  }

  performPostSearch() {
    if (this.state.searchKeywords && this.state.searchKeywords.length >= 3) {
      this.toggleSearch();
      navigate(`/app/searchPost/${encodeURI(this.state.searchKeywords)}/1`)
    }
  }
  render() {
    const { mobileActive, searchActive } = this.state;
    return (
      <nav>
        <Box width="100%" px={[3, 3, 4]}  className={fullNav}>
          <ul>
          {this.props.data.allGhostPage.edges
                    .map((page, index) => {
                    return (
                        <li key={page.node.id}>
                          <Link to={`/tag/${page.node.primary_tag.slug}`}>{page.node.title}</Link>
                        </li>
                    );
                })}            
            <li key={'all'}>
              <Link to={`/allPostList/`}>{'All'}</Link>
            </li>
          </ul>
          <div
                onClick={this.toggleSearch}
                role="button"
                tabIndex="0"
                onKeyPress={this.toggleSearch}
                style={{cursor: 'pointer', marginTop:5}}
              >          
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z"></path>            
            </svg>
          </div>
        </Box>
        <Box width="100%" px={[3, 3, 4]} className={mobileNav}>
          <ul>
            <li>
              <div
                onClick={this.toggleNav}
                role="button"
                tabIndex="0"
                onKeyPress={this.toggleNav}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"></path>
                </svg>
              </div>
            </li>
          </ul>
          <div
                onClick={this.toggleSearch}
                role="button"
                tabIndex="0"
                onKeyPress={this.toggleSearch}
                style={{cursor: 'pointer'}}
              >             
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z"></path>            
            </svg>          
          </div>
        </Box>
        {mobileActive && (
          <MobileNav toggleNav={this.toggleNav} mobileStyle={mobileStyle}>
            <ul>
            {this.props.data.allGhostPage.edges
                    .map((page, index) => {
                    return (
                        <li key={page.node.id}>
                          <Link to={`/tag/${page.node.primary_tag.slug}`}>{page.node.title}</Link>
                        </li>
                    );
                })}            
              <li key={'all'}>
                <Link to={`/allPostList/`}>All</Link>
              </li>
            </ul>
          </MobileNav>
        )}   
        {searchActive && (
          <MobileNav toggleNav={this.toggleSearch} mobileStyle={mobileStyle} didMount={this.setFocus}>
            <Box style={{textAlign: 'Center'}}>
              <h1>Search for Posts:</h1>
              <Box width={[2/3,1/2,1/4]} m={'0 auto 10px auto'}>
              <TextBox ref={this.setSearchTextBox} onEnterKey={this.performPostSearch} valueChangeEvent="keyup" onValueChanged={this.onKeywordValueChanged} placeholder="Enter at least 3 characters to search..." showClearButton={true}/>
              </Box>
              <Button icon="search"
                type="success"
                text="Search"
                onClick={this.performPostSearch}/>
              
            </Box>
              
          </MobileNav>
        )}             
      </nav>
    );
  }
}


const NavigationSettingsQuery = props => (
  <StaticQuery
      query={graphql`
          query NewsPages {
            allGhostPage(filter: {tags: {elemMatch: {slug: {eq: "hash-news-cat"}}}}, sort: {fields: published_at, order: ASC}) {
              edges {
                node {
                  id
                  title
                  primary_tag {
                    slug
                  }
                }
              }
            }
          }
      `}
      render={data => <SubNav data={data} {...props} />}
  />
)

export default NavigationSettingsQuery;