import React from 'react';
import { Box } from '../Layout';
import Helmet from 'react-helmet';
//import '../../styles/cms2.css';
import '../../styles/screen.css';
import '../../styles/addedon.css';
import { getFirebaseIdToken, isBrowser } from "../../utils/auth";
import SubNav from '../Navigation/subNav';
import colors from '../../utils/colors';
import { Tags } from '@tryghost/helpers-gatsby';
import Link from 'gatsby-link';

class SinglePostNew extends React.Component {
    constructor(props) {
        super(props);
        if (props.post)
            this.state = {post: props.post};
        else
            this.state = {post: null};
        this.displayAsPage = false;
        this.refresh = this.refresh.bind(this);
        if (!props.post)
            this.refresh();
    }


    refresh() {
        getFirebaseIdToken(this.props.firebase, ()=>{
            let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/readPostBySlug?slug=${this.props.slug}`;
            //let postApiUrl = `${process.env.GATSBY_CMS_API_URL}/browseAllPost?type=${this.props.type}&parentId=${this.props.parentId}&limit=${this.props.limit}&page=${this.currentPage}`;

            var request = new XMLHttpRequest();
            request.callObj = this;
            request.open('GET', postApiUrl,true);
            request.setRequestHeader('Content-Type','application/json; charset=utf-8');
            request.setRequestHeader('Authorization',`Bearer ${isBrowser()?window.localStorage.getItem("userIdToken"):''}`);
            request.onreadystatechange = function (reposta) {
            if (this.readyState === 4) {
                //console.log(this.responseText);
                var post = JSON.parse(this.responseText);
                if (!post.feature_image) {
                    var postCatTag = post.tags.find(tag=> tag.feature_image);
                    if (postCatTag)
                        post.feature_image = postCatTag.feature_image;
                }                 
                this.callObj.setState({post: post});
            }
            };
            request.send();
        });
    }
    
    render() {

        if (!this.state.post)
            return <></>;
        var returnUrl = isBrowser()?encodeURI(window.location.pathname + window.location.search):'';        
        if (returnUrl && returnUrl.startsWith('/post/'))
            returnUrl = '/app' + returnUrl;
        return (
            <>
            <Helmet>
                <style type="text/css">{`${this.state.post.codeinjection_styles}`}</style>
            </Helmet>

            <Box width={[1, 1, 2/3]} minWidth={[0,0,'1024px']} ml={[0,0,'auto']} mr={[0,0,'auto']}>
                <Box mt={['4rem']}
                    mb={['1.5rem', '1.5rem', '1.5rem']}>    
                    <SubNav/>
                </Box>
                <Box mb={['1.5rem', '1.5rem', '1.5rem']}>
                    <div style={{
                        backgroundImage: `url(${this.state.post.feature_image})`,
                        backgroundPosition: 'center',
                        backgroundColor: colors.secondary, 
                        backgroundSize: 'cover',
                        position: 'relative', 
                        height:'300px', 
                        width:'100%',
                        marginBottom: '20px'}}>
                            <div style={{position: 'absolute', 
                                top: 0, 
                                left: 0, 
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(22,22,22,.4)',
                                textAlign: "center",
                                color: colors.primary,
                                padding: '50px 20px 20px'}}>
                                    <Tags classes={'post-tag-new'} limit={1} post={this.state.post} visibility="public" autolink={true} permalink="/tag/:slug" />
                                    <h1 style={{color: colors.primary}}>{this.state.post.title}</h1>
                                    


                                    <span style={{color: colors.primary}}>by {this.state.post.primary_author.name}<br/>
                                    {(new Date(this.state.post.published_at)).toLocaleString(isBrowser()?window.navigator.userLanguage || window.navigator.language:'en-US',{year: 'numeric',month: 'long',day: 'numeric'})}</span>
                            </div>
                    </div>                
                    <article >
                        <section className="post-full-content">
                            {this.state.post.html?
                            <div className="post-content" dangerouslySetInnerHTML={{ __html: this.state.post.html }}>
                            </div>:
                            <div>
                            <div className="post-content">
                                {this.state.post.excerpt}
                            </div>
                            <div style={{backgroundColor: '#161616',
                                        textAlign: "center",
                                        color: '#fff',
                                        marginTop: '30px'}}>
                                <div style={{padding: "30px 20px"}}>
                                <h2>This post is for subscribers only</h2>
                                <br/><span style={{backgroundColor: '#fff', color: '#161616', padding:"10px 15px", marginTop: "200px"}}><Link to={'/register/'}>Register Now</Link></span><br/><br/>
                                <span>Already have an account? Please <Link to={`/app/login?returnUrl=${returnUrl}`}><span style={{color: colors.secondary}}>login</span></Link></span>
                                </div>
                            </div>
                            </div>
                            }
                            
                        </section>
                        <Tags classes={'post-tag-new'} separator={" "} post={this.state.post} visibility="public" autolink={true} permalink="/tag/:slug" />
                    </article>
                    
                </Box>
            </Box>
            </>

        );
    }
}
    
export default SinglePostNew;